/* CTA.css */
.cta {
  text-align: center;
  padding: 80px 20px;
}

.cta h2 {
  font-size: 32px;
  margin-bottom: 24px;
}

.cta p {
  font-size: 20px;
  color: #4b5563;
  margin-bottom: 32px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}