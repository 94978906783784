.card {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card-icon {
  color: #2563eb;
  margin-bottom: 16px;
}

.card-title {
  font-size: 20px;
  margin-bottom: 16px;
}

.card-description {
  color: #4b5563;
  line-height: 1.5;
}