/* styles/Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 93, 255);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 80px;
  display: flex;
  align-items: center;
}

.navbar-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
  gap: 12px;
}

.navbar-logo {
  height: 75px; /* Adjust based on your logo's proportions */
  width: auto;
  object-fit: contain;
}

.navbar-titles {
  display: flex;
  flex-direction: column;
}

.navbar-title {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.2;
}

.navbar-subtitle {
  font-size: 14px;
  color: #ffffff;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 32px;
  list-style: none;
}

.navbar-links a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #ebe324;
}

.navbar-button {
  background-color: #ebe324;
  color: rgb(0, 93, 255);
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navbar-button:hover {
  background-color: #ebe324;
}

/* Mobile Menu Styles */
@media (max-width: 768px) {
  .navbar-links {
	display: none;
  }
  
  .navbar-brand {
	gap: 8px;
  }
  
  .navbar-logo {
	height: 60px; /* Slightly smaller on mobile */
  }
  
  .navbar-title {
	font-size: 20px;
  }
  
  .navbar-subtitle {
	font-size: 12px;
  }
}