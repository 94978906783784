/* Features.css */
.features {
  background-color: #f9fafb;
  padding: 80px 0;
}

.features h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 48px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 48px;
  padding: 0 20px;
}

.feature-column h3 {
  font-size: 24px;
  margin-bottom: 24px;
}

.feature-column ul {
  list-style: none;
}

.feature-column li {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  color: #4b5563;
}

.feature-column li svg {
  color: #2563eb;
  flex-shrink: 0;
}