.custom-button {
  background-color: #2563eb;
  color: white;
  padding: 16px 32px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.custom-button:hover {
  background-color: #1d4ed8;
}