.hero {
  position: relative;
  height: 600px;
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
  width: 90%;
  max-width: 1000px;
  font-size: 22px;
}

.hero-content h1 {
  font-size: 48px;
  margin-bottom: 24px;
}

.hero-content p {
  font-size: 20px;
  margin-bottom: 32px;
}