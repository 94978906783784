/* Mission.css */
.mission {
  padding: 80px 0;
  background-color: white;
}

.mission h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 48px;
}

.mission-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  padding: 0 20px;
}